import React, { ReactNode } from 'react';
import { StyleSheet, View } from 'react-native';

interface ButtonListProps {
  children?: ReactNode;
}

const ButtonList = ({ children }: ButtonListProps) => {
  return (
    <View style={styles.buttonList}>
      {children}
    </View>
  )
}

export default ButtonList

const styles = StyleSheet.create({
  buttonList: {
  }
})
