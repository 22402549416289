import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { ApiLossType } from '../ApiTypes'

interface lossState {
  value: ApiLossType | null
}

const initialState = { value: null } as lossState

const lossSlice = createSlice({
  name: 'loss',
  initialState,
  reducers: {
    setLoss (state, action: PayloadAction<ApiLossType>) {
      state.value = action.payload
    }
  }
})

export const { setLoss } = lossSlice.actions
export default lossSlice.reducer
