import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { ApiDetails } from '../ApiTypes'

interface DetailsState {
  value: ApiDetails
}

const initialState = {
  value: {
    email: '',
    firstName: '',
    mileage: '',
    mobileNumber: '',
    postcode: '',
    registrationNumber: '',
    policyNumber: '',
    repeatEmail: '',
    surname: '',
    damageDate: ''
  }
} as DetailsState

const detailsSlice = createSlice({
  name: 'details',
  initialState,
  reducers: {
    setDetails (state, action: PayloadAction<ApiDetails>) {
      state.value = action.payload
    },
    setEmail (state, action: PayloadAction<string>) {
      state.value.email = action.payload
    },
    setFirstName (state, action: PayloadAction<string>) {
      state.value.firstName = action.payload
    },
    setMileage (state, action: PayloadAction<string>) {
      state.value.mileage = action.payload
    },
    setMobileNumber (state, action: PayloadAction<string>) {
      state.value.mobileNumber = action.payload
    },
    setPostcode (state, action: PayloadAction<string>) {
      state.value.postcode = action.payload
    },
    setRegistrationNumber (state, action: PayloadAction<string>) {
      state.value.registrationNumber = action.payload
    },
    setPolicyNumber (state, action: PayloadAction<string>) {
      state.value.policyNumber = action.payload
    },
    setRepeatEmail (state, action: PayloadAction<string>) {
      state.value.repeatEmail = action.payload
    },
    setSurname (state, action: PayloadAction<string>) {
      state.value.surname = action.payload
    },
    setDamageDate (state, action: PayloadAction<string>) {
      state.value.damageDate = action.payload
    }
  }
})

export const { setDetails, setEmail, setFirstName, setMileage, setMobileNumber, setPostcode, setRegistrationNumber, setRepeatEmail, setSurname, setPolicyNumber, setDamageDate } = detailsSlice.actions
export default detailsSlice.reducer
