import { configureStore } from '@reduxjs/toolkit'

import detailsSliceReducer from './DetailsSlice'
import policySliceReducer from './LossSlice'
import lossSliceReducer from './PolicySlice'
import smartClaimReducer from './SmartClaimSlice'

const store = configureStore({
  reducer: {
    details: detailsSliceReducer,
    policy: policySliceReducer,
    loss: lossSliceReducer,
    smartClaim: smartClaimReducer
  }
})

export default store

export type RootState = ReturnType<typeof store.getState>
