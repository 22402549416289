import { View } from 'react-native';
import React from 'react';
import styles from '../../constants/Styles'

function Bottom(props) {
  return (
    <View style={styles.bottom}>
      {props.children}
    </View>
  );
}

export default Bottom;
