import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { ApiDamageLocation, ApiDamageType, ApiSmartClaim } from '../ApiTypes'

interface smartClaimState {
  value: ApiSmartClaim
}

const defaultSmartClaimState: ApiSmartClaim = {
  allPhotos: [],
  id: null,
  damageLocation: null,
  damageType: null,
  date: null,
  meetsShineBusinessRules: 'false',
  vehicleAddress: '',
  vehiclePostcode: ''
}

const initialState = { value: defaultSmartClaimState } as smartClaimState

const smartClaimSlice = createSlice({
  name: 'smartClaim',
  initialState,
  reducers: {
    addPhoto (state, action: PayloadAction<string>) {
      //if (!state.value.allPhotos.includes(action.payload)) {
        state.value.allPhotos.push(action.payload)
      //}
    },
    deletePhoto (state, action: PayloadAction<string>) {
      state.value.allPhotos = state.value.allPhotos.filter((image) => image !== action.payload)
    },
	clearPhotos (state) {
		state.value.allPhotos.length = 0
	},
    setDamageLocation (state, action: PayloadAction<ApiDamageLocation>) {
      state.value.damageLocation = action.payload
    },
    setDamageType (state, action: PayloadAction<ApiDamageType>) {
      state.value.damageType = action.payload
    },
    setVehiclePostcode (state, action: PayloadAction<ApiSmartClaim>) {
      state.value.vehiclePostcode = String(action.payload)
    }
  }
})

export const { addPhoto, deletePhoto, setDamageLocation, setDamageType, setVehiclePostcode, clearPhotos} = smartClaimSlice.actions
export default smartClaimSlice.reducer
