import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { ApiPolicy } from '../ApiTypes'

interface policyState {
  value: ApiPolicy | null
}

const initialState = { value: null } as policyState

const policySlice = createSlice({
  name: 'policy',
  initialState,
  reducers: {
    setPolicy (state, action: PayloadAction<ApiPolicy>) {
      state.value = action.payload;
    }
  }
})

export const { setPolicy } = policySlice.actions
export default policySlice.reducer
