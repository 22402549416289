import React, { useState } from 'react'
import { StyleSheet, View } from 'react-native'
import { KeyboardAwareScrollView } from 'react-native-keyboard-aware-scroll-view'
import AddressSelectOptions from '../../components/AddressSelectOptions'
import API from '../../API'
import ConditionalButton from '../../components/ConditionalButton'
import DefaultButton from '../../components/DefaultButton'
import LabeledTextField from '../../components/LabeledTextField'
import NavigationBar from '../../components/NavigationBar'
import Para from '../../components/Para'
import ScreenHeading from '../../components/ScreenHeading'
import VerticalSpace from '../../components/VerticalSpace'
import Styles from '../../../constants/Styles'

interface SmartLocationProps {
  navigation: any
  onContinue: Function
  goHome: Function
  goBack: Function
  api: any
}

export default function SmartLocation ({ navigation, onContinue, goHome, goBack, api }: SmartLocationProps) {
  const [postcode, setPostcode] = useState('')
  const [addresses, setAddresses] = useState(null)
  const [house_number, setHouseNumber] = useState('')
  const [confirmed_postcode, setConfirmedPostcode] = useState('')
  const [town, setTown] = useState('')
  const [street_name, setStreetName] = useState('')
  const [selectedAddress, setSelectedAddress] = useState(JSON.parse('{"postcode":"","post_town":"","thoroughfare":"","building_number":"3","building_name":"","postal_county":"","sub_building_name":""}'))

  const formatAddress = (address : any) => {
    let formattedAddress = ''
    if (address.building_name) formattedAddress += `${address.building_name}`
    if (address.building_number) formattedAddress += ` ${address.building_number},`
    if (address.thoroughfare) formattedAddress += ` ${address.thoroughfare}`
    return formattedAddress
  }

  const canContinue = () => selectedAddress !== ''
  

  const setSelectedAddressFn = (address) => {
	  setHouseNumber(address.building_number ? address.building_number : address.building_name)
	  setConfirmedPostcode(address.postcode)
	  setStreetName(address.thoroughfare)
	  setTown(address.post_town)
  }

  const vehiclePostcodeChanged = (text: string) => {
	  setConfirmedPostcode(text)
  }

  function vehicleHouseNumberChanged(text: string){
	  setHouseNumber(text)
  }

  const vehicleStreetNameChanged = (text: string) => {
	  setStreetName(text)
  }

  const vehicleTownChanged = (text: string) => {
	  setTown(text)
  }

  
  const findAddresses = () => {
    const done = (data : any) => {
      setAddresses(data)
    }
    const fail = () => {
      setAddresses([])
    }
    api.postcodeLookup(postcode, done, fail)
  }
  
  function doContinue()
  {
	  //postcode, address
	  let address = selectedAddress;
	  address.building_number = house_number;
	  address.thoroughfare = street_name;
	  address.post_town = town;
	  address.postcode = confirmed_postcode;
	  console.log(address);
	  onContinue(confirmed_postcode, formatAddress(address))
  }

  return (
    <View>
      <ScreenHeading text="Book Repair" goBack={goBack}/>
      <View style={styles.container}>
        <Para>
          Please provide the postcode of your preferred repair location.
        </Para>
        <LabeledTextField label='Postcode' onChange={setPostcode} value={postcode}/>
        <VerticalSpace height={10} />
        <DefaultButton onClick={() => findAddresses()} text="Look up"/>
        <VerticalSpace height={10} />
        <AddressSelectOptions addresses={addresses} formatAddress={formatAddress} onChange={setSelectedAddressFn} />
        <Para>Or provide the address here:</Para>
        <LabeledTextField label='House number/name' onChange={vehicleHouseNumberChanged} value={house_number}/>
        <LabeledTextField label='Street name' onChange={vehicleStreetNameChanged} value={street_name}/>
        <LabeledTextField label='Town/City' onChange={vehicleTownChanged} value={town}/>
        <LabeledTextField label='Postcode' onChange={vehiclePostcodeChanged} value={confirmed_postcode}/>
        <VerticalSpace height={15}/>
        <ConditionalButton onClick={doContinue} text={'Submit'} condition={canContinue}/>
        <VerticalSpace height={15}/>
      </View>
    </View>
  )
}

const styles = StyleSheet.create({
  container: {
    paddingHorizontal: Styles.standardPadding
  },
  outerContainer: {
    alignSelf: 'center',
    maxWidth: 500,
    width: '100%'
  }
})
